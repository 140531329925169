






















































































































import { Component, Vue } from 'vue-property-decorator'
import { BContainer, BRow, BCol, BImg, BButton, VBToggle, BCard, BCardText, BFormInput, BCardBody, BModal, VBModal, BBadge, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

@Component({
  name: 'FaqPage',
  components: {
    BContainer, BRow, BCol, BImg, BButton, AppCollapse, AppCollapseItem, BCard, BCardText, BCardBody, BFormInput, BModal, vSelect, BBadge, BInputGroup, BInputGroupAppend
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal
  }
})
export default class FaqPage extends Vue {
  private faqsList : any[] = [
    {
      title: 'What is Washir?',
      content: 'Washir is a technology company which allows users to order a wash from anywhere to our network of existing mobile washir -pros.',
      additional: '',
      list: []
    },
    {
      title: 'Who is Washir-Pro?',
      content: 'Washir is a platform provider and does not serve as a cleaning service or a laundry company. Our professional washer and detailers run all services. They are ready to do different types of services based on location. Each Washir-Pro has his/her specialty, and Washir provides a right Washir-Pro based on customer order.',
      additional: 'You can see all about the Washir-Pro before you get your service. If he/she does not match your criteria, you can cancel the order.',
      list: []
    },
    {
      title: 'How can I become a Washir-Pro?',
      content: 'If you are a professional do a car wash and detailing, and you have all the necessary licenses and equipment, you can register on Washir as a Washir-Pro. After your submission approved, we will send the carwash request to your app and based on your location and services you provide; you can accept the orders.',
      additional: '',
      list: []
    },
    {
      title: 'How do I get paid as a Washir-Pro?',
      content: 'After finishing the request and customer confirmation that they received the service, we will charge the customer. We will process the payment to "Washir-Pro" as soon as we receive the charge.',
      additional: '',
      list: []
    },
    {
      title: 'Do you wash bikes, trucks, boats or planes?',
      content: 'We are offering a wide range of variety of services for all the vehicles such as cars, trucks and big trucks. Cleaning bikes, boats, and planes will are added very soon.',
      additional: '',
      list: []
    },
    {
      title: 'How and when I can cancel my order?',
      content: ' If you order a "wash-now" or a washir-pro accept your order less than one hour of the appointment time, you have 15 min to cancel your order <b>after Washir-Pro accepts your order. If you have an appointment for more than one hour and a "Washir-Pro" accepts your request, you only can cancel your order until one hour before your appointment.',
      additional: 'There will be a $10 cancelation fee if you cancel your order.',
      list: []
    },
    {
      title: 'What to do when your washer cancels your order?',
      content: 'When a Washir-Pro cancels the order, we will send your request to all the other available "Washir-Pros" in your area. If there were no Washir-Pro available, your order would be canceled, and you need to order again.',
      additional: '',
      list: []
    },
    {
      title: 'About our packages?',
      content: 'We offer four standard packages that you may choose from. There is a vast range of extra options that you can choose based on your need.',
      additional: '',
      list: [
        'GLINT is a 30 - 45 min job that we do full exterior hand-wash, clean windows in and out',
        'SHINE is a 50 - 70 min job that includes our Glint package, interior wipe down, clean and dress the wheels, interior & trunk vacuum.',
        'GLORY is a 75 - 90 min job that includes our Shine package, a layer of wax for protecting & shine light stain removal',
        'LUX is a 90 - 120 min job that includes our Glory package, stain removal, remove water spots, a layer of clay bar paint treatment, shampoo mats, and carpets'
      ]
    },
    {
      title: 'What are the requirements to order services?',
      content: 'The Washir-Pro will come fully prepared to complete your order. Make sure you are present at the time of washing (otherwise cancelation fee will apply). Also, make sure you have required permissions to do wash and detailing in your location (otherwise cancelation fee will apply).',
      additional: '',
      list: []
    },
    {
      title: 'How can you add multiple cars to order?',
      content: 'After selecting your location, you can select one of the saved cars or add a new car. After finishing your first order, before payment method, you have an option to add another car. You only can add up to 5 cars to a single order.',
      additional: '',
      list: []
    },
    {
      title: 'How can you add multiple appointment time to order?',
      content: 'After selecting your location and car and packages, you should add your best time to do the service. You can add up to 5 different times to your order. It gives more flexibility to our Washir-Pro to reach you as soon as possible they can base on your and their location.',
      additional: '',
      list: []
    },
    {
      title: 'What to do when I place an order?',
      content: 'After a minute of requesting your wash, the app will send your request to all our local "Washir-Pros." Based on your car(s), package, options and your availabilities, one of our "Washir-Pro" will accept your request. After "Washir-Pro" accepts your request, we will send you a notification, and you can see the profile, specialties, history, comments, and rating of the "Washir-pro." If you are not happy with that choice, you can cancel your order.',
      additional: '',
      list: []
    },
    {
      title: 'How can you refer a friend?',
      content: `Our referral program is certainly very rewarding. Invite a friend to Washir, and consequently, you'll be awarded a credit once the person has been referred. From the menu, you can go to the promo page and get the invite friend code. You can send it to a friend or family member, and both will get the credit when your friend uses the code and order a service.`,
      additional: '',
      list: []
    },
    {
      title: 'When and where to apply your promo code?',
      content: 'You can apply your promo code at checkout or from the main menu. You can also invite your friends and family, and both of you will get credit when your friend or family member uses the code and order a service.',
      additional: '',
      list: []
    },
    {
      title: 'How can you contact your Washir-Pro?',
      content: 'When your Washir-Pro accepts your order, you can go to order summary page and text or call the Washir-Pro.',
      additional: '',
      list: []
    },
    {
      title: 'How can you order a wash with a past washer?',
      content: 'This feature is not available now',
      additional: '',
      list: []
    },
    {
      title: 'What is a pre-authorization charge?',
      content: 'If you require a wash, the pre-authorization charge is in place to ensure that the credit card in the file is valid. Note that this charge will not pass until your cleaning service is complete.',
      additional: '',
      list: []
    },
    {
      title: 'How long will the service take?',
      content: 'Based on the package of options you selected, service will take between 30 min up to 2 hours.',
      additional: '',
      list: []
    },
    {
      title: 'Do you take credit cards?',
      content: 'As Washir is a cash-free platform, we accept all the major credit cards',
      additional: '',
      list: []
    },
    {
      title: 'What if Washir is not available in your area?',
      content: 'If washir is not available in your area, you can add your email to get the notification as soon as we start our service in your area.',
      additional: '',
      list: []
    },
    {
      title: 'How can you review your washer?',
      content: ` Once your order has been accepted, you will receive a notification indicating who your washer is and his/her overall rating. You can see all the previous comments and all the information about the "Washir-Pro." If you don't like, you can cancel your order and we will send your request to another "Washir-Pro."`,
      additional: '',
      list: []
    },
    {
      title: 'How can you tip your Washir-Pro?',
      content: 'At the end of your wash, when you confirm you received your order, you will have the option to rate and tip your Washir-Pro. While tipping is not necessary, it is appreciated.',
      additional: '',
      list: []
    }
  ];

  private queryString : String = ''
  get faqs () {
    return this.faqsList.filter(data => {
      return Object.keys(data).map(index => {
        return data[index].toString().toUpperCase().includes(this.queryString.toString().toUpperCase())
      }).includes(true)
    })
  }
  get leftFaqs () {
    const faqs = [...this.faqs]
    if (this.faqs.length <= 1) {
      return faqs
    }
    return faqs.splice(0, Math.ceil(this.faqs.length / 2))
  }
  get rightFaqs () {
    const faqs = [...this.faqs]
    if (faqs.length <= 1) {
      return []
    }
    return faqs.splice(Math.ceil(this.faqs.length / 2), this.faqs.length)
  }
}
